// project import
import React from 'react';
import Routes from './routes';
import ScrollTop from './components/ScrollTop';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    return (
        <ScrollTop>
            <Routes />
        </ScrollTop>
    );
};

export default App;
